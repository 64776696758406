.fixed-links {
  position: fixed;
  top: 65%;
  left: 3%;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70px;
  padding: 20px 0;
  z-index: 100;
}

.fixed-links a img {
  margin-bottom: 10px;
}
