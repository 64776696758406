.innerPolygon {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  background-size: contain;
  height: 280px;
  width: 280px;
  margin: auto;
  position: absolute;
  top: 3%;
  left: 3%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.outerPolygon {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  height: 300px;
  width: 300px;
  background-image: linear-gradient(to left, rgb(6 73 106), rgb(255 233 144));
  position: relative;
}
