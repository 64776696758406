.side_social_icons {
  background-color: rgb(255 255 255 / 70%);
}
.side_social_icons:hover {
  background-color: rgb(255, 220, 110, 0.7);
}

.item_conatainer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}
.fsiz {
  font-size: 0.8rem;
}

.item_title {
  background-image: linear-gradient(
    to right,
    transparent,
    rgb(161 161 161 / 60%),
    rgb(207 207 207 / 80%)
  );
  padding: 25px 40px 25px 60px;
  border-radius: 3px;
  text-shadow: #757575 2px 2px;
  font-size: x-large;
}

.borderside {
  padding: 7px 10px 7px 10px;
  border: blue 2px solid;
  border-left-color: transparent;
  border-right-color: #cfcfcf;
  border-top-color: #a1a1a1;
  border-bottom-color: #757575;
}

.main_logo {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  width: 120px;
}

@media only screen and (max-width: 992px) {
  .main_logo {
    width: 100px;
  }
}
@media (max-width: 920px) {
  .main_logo {
    width: 90px;
    top: 2px;
    right: 2px;
  }
}
.mainSlider .borderside {
  padding: 7px 10px 7px 10px;
  border: blue 2px solid;
  border-left-color: transparent;
  border-right-color: #cfcfcf;
  border-top-color: #a1a1a1;
  border-bottom-color: #757575;
}
.mainSlider .carousel-control-prev,
.mainSlider .carousel-control-next {
  display: none;
}

.imgsz {
  width: 35px;
}

@media only screen and (max-width: 480px) {
  .imgsz {
    width: 20px;
  }
  .spansz {
    font-size: 11px;
  }
}
