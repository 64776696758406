.ContainerDiv {
  border-radius: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  min-width: 50%;
  position: relative;
  height: 450px;
}

.imageDiv {
  border-radius: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  background-size: cover;
  height: 450px;
}

.projectsButtons {
  position: absolute;
  bottom: 5%;
  left: 5%;
}

.projectAlbum {
  height: 300px;
  width: inherit;
}

.rightControl > i,
.leftControl > span,
.leftControl > i {
  color: black;
}

.rightControl > i,
.rightControl > span {
  right: 50% !important;
  position: absolute !important;
  top: 50% !important;
  z-index: 5 !important;
  display: inline-block !important;
  margin-top: -10px !important;
}

.carousel-control-next,
.carousel-control-prev {
  width: 0 !important;
}

@media (min-width: 1199px) {
  .rightControl > i,
  .rightControl > span,
  .leftControl > i,
  .leftControl > span {
    font-size: 35px !important;
  }

  .leftControl > i,
  .leftControl > span {
    margin-left: -20px !important;
  }

  .rightControl > i,
  .rightControl > span {
    margin-right: -20px !important;
  }
}

@media (min-width: 992px) {
  .projectsDiv {
    height: 455px;
  }

  .ContainerDiv {
    width: 100%;
  }

  .imageDiv {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .leftControl > i,
  .leftControl > span {
    margin-left: -10px !important;
  }

  .rightControl > i,
  .rightControl > span {
    margin-right: -10px !important;
  }
}

@media (max-width: 767px) {
  .imageDiv {
    width: 95%;
  }

  .ContainerDiv {
    width: 95%;
    height: 700px;
  }

  .projectsDiv {
    height: 1100px;
  }

  .rightControl > i,
  .rightControl > span,
  .leftControl > i,
  .leftControl > span {
    top: 39% !important;
  }

  .leftControl > i,
  .leftControl > span {
    margin-left:0 !important;
  }

  .rightControl > i,
  .rightControl > span {
    margin-right: 0 !important;
  }
}

.underline::after {
  content: "";
  height: 4px;
  background-color: #ffdc6e;
  margin-right: 10px;
  position: absolute;
  width: 160px;
}

.detailsPhoto {
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 6px -1px,
    rgb(0 0 0 / 6%) 0px 2px 4px -1px;
}

.detailsSectorName {
  width: 160px;
  border-radius: 10px;
  background-color: rgb(255 220 110 / 80%) !important;
}
