.list_container {
  height: 21rem;
  box-shadow: rgb(0 0 0 / 40%) 10px 11px 10px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
  border-radius: 10px;
  overflow: hidden;
}
.list_img_container {
  overflow: hidden;
  position: relative;
}
.list_container:hover .list_img {
  opacity: 0.8;
  transform: scale(1.2);
}
.list_img {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  opacity: 1;
  -moz-transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.list_category {
  position: absolute;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  left: 0;
  top: 15px;
  padding: 2px 6px;
  background-color: rgb(6, 73, 106);
  color: white;
}

.list_percentage {
  position: absolute;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  left: 0;
  fill: white !important;
  bottom: 15px;
  padding: 2px 6px;
  background-color: rgb(6, 73, 106) !important;
  font-weight: bold;
}

.list_container {
  color: #000;
  text-decoration: unset !important;
}
