.holder {
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 40%) 10px 11px 10px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
  height: 350px;
  overflow: hidden;
  position: relative;
}

.custom-holder{
  height: auto !important;
}

.imageAlbum {
  border-radius: 5px;
  cursor: pointer;
  background-size: cover;
  height: 350px;
  opacity: 1;
  -moz-transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  background-position: center;
}

.imageAlbum:hover {
  opacity: 0.8;
  transform: scale(1.2);
}
