/* .fc-theme-standard .fc-scrollgrid,
.fc td, .fc th {
    border: 3px solid white !important;
} */

.fc-col-header-cell .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  color: #c2c2c2;
}
.training-agenda-bg {
  background-image: url("../assets/img/background1.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.agenda-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
}
.show-all {
  position: relative;
  z-index: 20;
}
.agenda-title,
.no-events {
  position: relative;
  z-index: 20;
}
/* 
.fc-scrollgrid  thead tr td .fc-scroller-harness{
    margin-bottom: 1.5rem;
}

.fc-daygrid-day-frame{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fc-daygrid-day{
    background-color: transparent;
    font-size: x-large;
}

.fc-day-past .fc-daygrid-day-top, .fc-day-future .fc-daygrid-day-top{
    background-color: #ddd;
    width: 65px;
    height: 65px;
}



.fc-toolbar-title{
    color: #d8b65e;
}

.fc-daygrid-day-number{
    margin: auto;
}

.fc-event-title{
    font-size: 1rem;
}

.fc-daygrid-more-link{
    color: #aaa;
}

.fc .fc-daygrid-day-bottom {
    font-size: 0.7em;
}

.fc .fc-daygrid-day.fc-day-today{
    background-color: transparent !important;
}
*/
.fc-daygrid-day.fc-day.fc-day-sun.fc-day-past .fc .fc-daygrid-day.fc-day-today,
.fc .fc-daygrid-day.fc-day-future {
  height: 5rem !important;
}

.fc .fc-daygrid-day-number {
  color: black;
  text-decoration: none;
}

.fc-col-header-cell-cushion {
  text-decoration: none;
}

.fc .fc-today-button {
  display: none;
}

.fc .fc-button-primary {
  background-color: orange !important;
}

.fc .fc-button-primary:hover,
.fc .fc-button-primary:active:before {
  background-color: #faa74a !important;
}

/* .fc-daygrid-day > .fc-scrollgrid-sync-inner .fc-daygrid-day-events .fc-daygrid-event-harness-abs{
    background-color: red !important;
} */

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child),
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  border-radius: 0.25em;
}
.fc .fc-button-primary {
  border-color: transparent !important;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  margin-right: 1.5rem;
}
.fc-col-header-cell .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  color: black;
  font-size: 14px;
}
@media only screen and (max-width: 767.9px) {
  .fc-col-header-cell .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
    font-size: 12px;
  }
}
