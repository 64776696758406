.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: rgb(6, 73, 106) !important;
}

.MuiButton-textPrimary {
  color: rgb(6, 73, 106) !important;
}
.MuiDialogActions-root {
  flex-direction: row-reverse;
}
